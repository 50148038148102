import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/styles'
import Typography from '@material-ui/core/Typography'

import ExternalLink from '../atoms/ExternalLink'
import ContentArticle from '../atoms/ContentArticle'
import SvgFlcIconBlank from '../atoms/SvgFlcIconBlank'

import theme from '../../styles/theme'

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: theme.spacing(2),

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    icon: {
        position: 'relative',
        width: '38px',
        flexShrink: 0,
        marginBottom: theme.spacing(1),

        [theme.breakpoints.up('sm')]: {
            top: '2px',
            marginBottom: 0,
            marginRight: theme.spacing(2.5),
        },

        '& span': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '-3px 0 0 0',
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: theme.typography.pxToRem(30),
            color: theme.palette.primary.main,
            lineHeight: 1,
            zIndex: 1,
        },

        '& svg': {
            width: '100%',
        },
    },
    title: {
        margin: 0,
    },
    body: {
        backgroundColor: theme.palette.primary.light,
    },
    description: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,

        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
        },
    },
    imageLinks: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(4),
        backgroundColor: theme.palette.primary.dark,

        [theme.breakpoints.up('sm')]: {
            justifyContent: 'space-evenly',
        },

        '& a': {
            display: 'block',
            width: '100%',

            '&.halo-image-link': {
                maxWidth: '110px',

                [theme.breakpoints.up('md')]: {
                    maxWidth: '120px',
                },

                [theme.breakpoints.up('lg')]: {
                    maxWidth: '130px',
                },
            },

            '&.nzcb-link': {
                maxWidth: '70px',

                [theme.breakpoints.up('md')]: {
                    maxWidth: '80px',
                },

                [theme.breakpoints.up('lg')]: {
                    maxWidth: '90px',
                },
            },
        },
    },
}))

function Benefit({ title, description, imageLinks, index }) {
    const classes = useStyles()

    return (
        <ContentArticle>
            <header className={classes.header}>
                <div className={classes.icon}>
                    <Typography variant={'h1'} component={'span'}>
                        {index + 1}
                    </Typography>
                    <SvgFlcIconBlank color={theme.palette.primary.light} />
                </div>
                <Typography className={classes.title} variant="h1">
                    {title}
                </Typography>
            </header>
            <div className={classes.body}>
                <Typography
                    variant="body1"
                    component={'div'}
                    className={classes.description}
                    dangerouslySetInnerHTML={{
                        __html: description.childMarkdownRemark.html,
                    }}
                />
                {imageLinks && (
                    <div className={classes.imageLinks}>
                        {imageLinks.map((imageLink) => {
                            return (
                                <ExternalLink
                                    key={imageLink.id}
                                    className={imageLink.className}
                                    link={imageLink.link}
                                    gaEventLabel={`Benefit: ${title}`}
                                    gaEventValue={imageLink.link}
                                    title={imageLink.image.description}
                                >
                                    <Img
                                        fluid={imageLink.image.fluid}
                                        alt={imageLink.image.description}
                                    />
                                </ExternalLink>
                            )
                        })}
                    </div>
                )}
            </div>
        </ContentArticle>
    )
}

Benefit.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
    imageLinks: PropTypes.array,
    index: PropTypes.number,
}

export default Benefit
