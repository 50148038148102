import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

function SvgFlcIconBlank({ className, color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 650 950"
      fill={color ? color : '#ffffff'}
      className={classnames(className ? className : null)}
    >
      <g>
        <path d="M0 49.059v600.706C0 815.59 145.406 950 325 950c179.595 0 325-134.41 325-300.235V49.059H0zM0 0h650v25.061H0z" />
      </g>
    </svg>
  )
}

SvgFlcIconBlank.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
}

export default SvgFlcIconBlank
