import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles((theme) => ({
    item: {
        position: 'relative',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.up('md')]: {
            marginRight: '150px',
            '&.isOdd': {
                marginRight: 'auto',
                marginLeft: '150px',
            },
        },
    },
}))

function ContentStaggeredItems({ items, itemComponent: Component }) {
    const classes = useStyles()

    return (
        <React.Fragment>
            {items.map((item, index) => {
                const isOdd = !!(index % 2)
                const componentProps = { index, ...item }

                return (
                    <div
                        key={item.id || index}
                        className={classNames(
                            classes.item,
                            isOdd ? 'isOdd' : null
                        )}
                    >
                        <Component {...componentProps} />
                    </div>
                )
            })}
        </React.Fragment>
    )
}

ContentStaggeredItems.propTypes = {
    items: PropTypes.array.isRequired,
    itemComponent: PropTypes.func,
}

export default ContentStaggeredItems
